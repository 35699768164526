/* eslint-disable no-async-promise-executor */

import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import OSS from 'ali-oss';
import axios from 'axios';

/**
 * 将文件上传至阿里云
 * @param {File} file 文件对象
 * @returns 
 */
export function uploader(file:File) : any {
  let requestCount = 0
  return new Promise (async (resolve, reject) => {
      try{
        const res = await uploadFile(file)
        resolve(res)
      }catch(err){
        // 如果token失效，就连续发送二次请求。
        if(requestCount == 0){
          requestCount++
          ex_stsTokenRes = null
          try{
            const res1 = await uploadFile(file)
            resolve(res1)
          }catch(e){
            reject(err)
          }
        }else{
          reject(err)
        }
      }
  })
}

async function uploadFile(file: File) {
  const client: any = await AliyunOssInit()
  const prefix = file.name.slice(file.name.lastIndexOf('.'))
  const res = await client.put(`/app/timeline/${dayjs().format('YYYYMMDD')}/${uuid()}` + prefix, file)
  // http:// 替换成 https://
  if(res.url)res.url = res.url.replace(/^http:\/\//i, 'https://')
  return res
}



// 临时令牌信息
let ex_stsTokenRes: any = null

function AliyunOssInit() {

  return new Promise(async (resolve, reject)=> {
    try{
      if(!ex_stsTokenRes) ex_stsTokenRes = await axios.get('https://ke.gupaoedu.cn/api/oss/sts')
      if(ex_stsTokenRes.code == 0){
        const data = ex_stsTokenRes.data
        const client = new OSS({
          // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
          accessKeyId: data.assumeRoleResponse.credentials.accessKeyId,
          accessKeySecret: data.assumeRoleResponse.credentials.accessKeySecret,
          // 从STS服务获取的安全令牌（SecurityToken）。
          stsToken: data.assumeRoleResponse.credentials.securityToken,
          // 填写Bucket名称，例如examplebucket。
          bucket: data.bucket,
          endpoint: data.endpoint,
          // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
          region: data.endpoint.split('.')[0],
        })
        resolve(client)
      }else{
        reject(new Error(' STS 临时 token获取失败'))
      }
    }catch(err){
      reject(err)
    }
  })
}