export default {
  ke: (base?: string): string => {
    return (base || '') + '/v1/api/ke'
  },
  admin: (base?: string): string => {
    return (base || '') + '/v1/api/admin'
  },
  crm: (base?: string): string => {
    return (base || '') + '/v1/api/crm'
  },
  s: (base?: string): string => {
    return (base || '') + '/v1/api/s'
  },
  wx: (base?: string): string => {
    return (base || '') + '/v1/api/wx'
  },
  wxm: (base?: string): string => {
    return (base || '') + '/v1/api/wxm'
  },
  moment: (base?: string): string => {
    return (base || '') + '/v1/api/moment'
  },
  market: (base?: string): string => {
    return (base || '') + '/v1/api/market'
  },
  passport: (base?: string): string => {
    return (base || '') + '/v1/api/passport'
  },
  dc: (base?: string): string => {
    return(base || '') + '/v1/api/dc'
  },
  notify: (base?: string): string => {
    return(base || '') + '/v1/api/notify'
  },
  wxcp: (base?: string): string => {
    return(base || '') + '/v1/api/chatsync'
  },
  // 被遗弃的。暂时不知道怎么处理。先这样做个标记，后面可以统一替换
  review: (base?: string): string => {
    return(base || '') + '/v1/api/market'
  },
}